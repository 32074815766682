import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {Observable, from, throwError} from 'rxjs';
import {switchMap, catchError} from 'rxjs/operators';
import {getApiGatewayUrl} from 'src/app/helpers/cloud-functions-helpers';

@Injectable({
  providedIn: 'root',
})
export class CloudFunctionsCallService {
  constructor(private afAuth: AngularFireAuth) {}

  callCloudFunction(
    endpoint: string,
    method: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS',
    body?: any
  ): Observable<Response> {
    return from(this.afAuth.currentUser).pipe(
      switchMap((user) => {
        if (!user) {
          return throwError(() => new Error('No user logged in'));
        }
        return from(user.getIdToken());
      }),
      switchMap((token) => {
        return from(
          fetch(getApiGatewayUrl() + '/' + endpoint, {
            method,
            mode: 'cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: body ? JSON.stringify(body) : undefined,
          })
        );
      }),
      catchError((error) => {
        console.error(`Error calling API Gateway endpoint ${endpoint}:`, error);
        return throwError(() => error);
      })
    );
  }
}
