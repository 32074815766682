import {DbStoreShippingMethodModel} from '../db-models/store';
import {DbCartModel, DbCartProductModel} from '../db-models/cart';
import {
  DbSessionLiveDataModel,
  DbSessionModel,
  DbSessionPollModel,
  DbSessionProductModel,
  featuredItemFieldSession,
  RecordedVideo,
  RtdbInfo,
  SessionFeature,
  TalkingPointHistory,
} from '../db-models/session';
import {
  CustomType,
  DbProductOptionModel,
  ProductCustomizationImage,
  ProductCustomizationText,
  ProductCustomProperties,
} from '../db-models/product';
import {PaymentSuppliers} from '../db-models/payments';
import type {FirestoreTimestamp} from '../types/firestore';
import {RTDBObject} from '../utilities/type-helpers';
import {
  DbViewersDiscountModel,
  DiscountCalculationType,
  DiscountDataInDbSessionModel,
  DiscountTypes,
} from '../types/discounts';

export class SessionDataDTO implements DiscountDataInDbSessionModel {
  id: string;
  storeId: string;
  description: string;
  coverImage?: string;
  hostUserId?: string;
  hostName: string;
  hostImage?: string;
  startTime: FirestoreTimestamp;
  hasEnded: boolean;
  isActive: boolean;
  currency: string;
  paymentSupplier: PaymentSuppliers;
  sessionFeatures: SessionFeature[] = [];
  sessionFeaturesData: DbSessionModel['sessionFeaturesData'];
  // TODO: remove in version greater than 1.13.x
  userLink: never | undefined;
  liveDataBackup: DbSessionLiveDataModel | undefined = undefined;
  //external video source
  externalVideoSource: string | undefined;
  externalVideoSourceMobile: string | undefined;
  isLandscapeSource: boolean | undefined;

  likes: number;
  hasUserLiked: boolean;
  // Relevant for OffSession only
  hasUserSessionReminder: boolean;
  recordedVideo?: RecordedVideo;

  isHorizontalView: boolean;
  // Settings
  additionalViewersManipulation: number;
  maxAllowedUsers?: number;
  minimumAllowedUsers?: number;
  entryFee?: number;
  entryFeeText?: string;
  actionsRequireLogin: boolean;
  analyticsLiveSessionMaxAge?: number;
  concurrentFeaturedItems?: number;

  products: SessionDataProductDTO[];
  productsAsMap: Record<string, SessionDataProductDTO>;
  shippingMethods: DbStoreShippingMethodModel[];
  cart?: SessionDataCartDTO;

  // Store
  taxName?: string;
  taxPercent?: number;

  // Discounts
  discountType: DiscountTypes;
  generalDiscount?: number;
  generalDiscountCalculationType?: DiscountCalculationType;
  viewersPercentageDiscounts: DbViewersDiscountModel[];
  viewersPercentageDiscountsCalculationType?: DiscountCalculationType;
  viewersItemDiscounts: DbViewersDiscountModel[];
  viewersItemDiscountsCalculationType?: DiscountCalculationType;

  // Polls
  polls: DbSessionPollModel[];

  // LiveData
  liveData?: DbSessionLiveDataModel | null;

  // OffSession client only
  // wasReminded: boolean;

  analyticsVersion?: number;
  rtdbVersion?: number;
  rtdbInfo: RtdbInfo | undefined;

  postFeaturedItems?: featuredItemFieldSession[];
  talkingPoints?: string[];
  talkingPointsHistory?: Record<string, TalkingPointHistory>;

  twilioChatId?: string;
  twilioChatSid?: string;
  useTwilioChat: boolean = false;
  sessionURL?: string;

  constructor() {
    this.products = [];
    this.productsAsMap = {};
    this.shippingMethods = [];
    this.polls = [];
    this.viewersPercentageDiscounts = [];
    this.viewersItemDiscounts = [];
  }
}

export type Action =
  | 'delete' // Delete message
  | 'ban' // Ban user from this session chat only
  | 'edit'; // Edit message written by the user

export type SessionChatModerationMutePayload = {
  minutes: number;
};

export type SessionChatModerationEditPayload = {
  text: string;
};

export type SessionChatModerationPayload =
  | SessionChatModerationMutePayload
  | SessionChatModerationEditPayload;

export type SessionChatModerationDTO = {
  sessionId: string;
  messageId: string;
  actions: Action[];
  payload?: SessionChatModerationPayload;
};

export interface Thumbnail {
  url: string;
  width: number;
  height: number;
}

export class SessionDataProductDTO extends DbSessionProductModel {
  originalPrice: number;
  fullProductData: SessionDataFullProductDTO | null;
}

export function convertRTDBResponseToSessionDataProductDTO(
  res: RTDBObject<SessionDataProductDTO>
): SessionDataProductDTO {
  const product = new SessionDataProductDTO();
  Object.entries(res).forEach(([key, value]) => {
    if (!value) delete (res as any)[key];
  });
  Object.assign(product, res);
  product.fullProductData = product.fullProductData
    ? convertRTDBResponseToFullProductDTO(product.fullProductData)
    : null;
  return product;
}

export function convertRTDBResponseToFullProductDTO(
  res: RTDBObject<SessionDataFullProductDTO>
): SessionDataFullProductDTO {
  const product = new SessionDataFullProductDTO();
  Object.entries(res).forEach(([key, value]) => {
    if (!value) delete (res as any)[key];
  });
  Object.assign(product, res);
  return product;
}

export function convertRTDBResponseToSessionDataProductVariantDTO(
  res: RTDBObject<SessionDataProductVariantDTO>
): SessionDataProductVariantDTO {
  const variant = new SessionDataProductVariantDTO();
  Object.entries(res).forEach(([key, value]) => {
    if (!value) delete (res as any)[key];
  });
  Object.assign(variant, res);
  return variant;
}

export function convertRTDBRecordResponseToRecordOfSessionDataProductDTO(
  res: RTDBObject<Record<string, SessionDataProductDTO>> | null
): Record<string, SessionDataProductDTO> {
  const products: Record<string, SessionDataProductDTO> = {};
  for (const key in res) {
    products[key] = convertRTDBResponseToSessionDataProductDTO(res[key]);
  }
  return products;
}

export class SessionDataCartDTO {
  userCart: DbCartModel;
  products: DbCartProductModel[];

  constructor() {
    this.products = [];
  }
}

export class SessionDataFullProductDTO {
  id: string = '';
  name: string = '';
  description: string = '';
  shortDescription: string = '';
  options: DbProductOptionModel[] = [];
  shippingMethodIds: string[] = [];
  measurementsUrl: string = '';
  integrationType?: string | null;

  // Customization
  isCustomized: boolean;
  customType: CustomType | null;
  customizationText: ProductCustomizationText | null;
  customizationImage: ProductCustomizationImage | null;
  customProperties?: ProductCustomProperties = {};

  variants: SessionDataProductVariantDTO[] = [];
  variantsAsMap: Record<string, SessionDataProductVariantDTO> = {};
}

export class SessionDataProductVariantDTO {
  id: string;
  optionValues: {[option: string]: string} = {};
  imageUrls: string[] = [];
  price: number;
  compareAtPrice: number | null;
  orderIndex: number | null;
  isOutOfStock: boolean;
  stock: number;
}

export class SessionSeatDTO {
  noMoreSeats: boolean;
  seatId: string;
  broadcastToken: string | null;
  deposit: number | null;
  usedDeposit: number | null;
  broadcastTokenUid: number | null;
}

export class PromoVideoDTO {
  id: string;
  isActive: boolean;
  name: string;
  link: string;
  length: number;
  lengthAsString: string;
  thumbnail: Thumbnail;
}

export class sessionRtdbDto {
  id: string;
  storeId: string;
  isActive: boolean;
  viewers: number;
}
