import {inject, Injectable, RendererFactory2} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {catchError, Observable, Subject, switchMap, take, throwError} from 'rxjs';
import {LogService} from './logger/logger.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private translateService = inject(TranslateService);
  private rendererFactory = inject(RendererFactory2);
  protected _toastr = inject(ToastrService);
  private logService = inject(LogService);
  private currentLangSubject: Subject<string> = new Subject<string>();

  public get currentLang() {
    return this.translateService.currentLang;
  }

  public get currentLang$() {
    return this.currentLangSubject.asObservable();
  }

  private readonly LOCAL_STORAGE_LANGUAGE_KEY_NAME = 'Lang';
  private supportedLanguages: Record<string, {isRtl: boolean}> = {
    en: {
      isRtl: false,
    },
    es: {
      isRtl: false,
    },
    fr: {
      isRtl: false,
    },
    he: {
      isRtl: true,
    },
    pol: {
      isRtl: false,
    },
    ja: {
      isRtl: false,
    },
  };

  public getSavedLanguage(): string | null {
    let lang: string | null = null;
    try {
      lang = localStorage.getItem(this.LOCAL_STORAGE_LANGUAGE_KEY_NAME);
    } catch (error) {
      // ignore
    }

    return lang;
  }

  public getSavedLanguageCurrentStore(storeId: string): string | null {
    return localStorage.getItem(storeId);
  }

  public removeLanguageCurrentStore(storeId: string) {
    localStorage.removeItem(storeId);
  }

  public saveLanguageCurrentStore(storeId: string, lang: string) {
    localStorage.setItem(storeId, lang);
  }

  public changeLanguage(lang: string) {
    if (this.supportedLanguages[lang]) {
      this.translateService.use(lang).pipe(take(1)).subscribe();
      this.currentLangSubject.next(lang);
      try {
        localStorage.setItem(this.LOCAL_STORAGE_LANGUAGE_KEY_NAME, lang);
      } catch (error) {
        // ignore
      }

      const renderer2 = this.rendererFactory.createRenderer(null, null);
      const scrollBars = document.getElementsByClassName('ng-scrollbar-wrapper');
      if (this.supportedLanguages[lang].isRtl) {
        if (scrollBars.length) {
          for (let i = 0; i < scrollBars.length; i++) {
            renderer2.setAttribute(scrollBars[i], 'dir', 'rtl');
          }
        }

        renderer2.addClass(document.body, 'rtl');
        renderer2.setAttribute(document.body, 'dir', 'rtl');
      } else {
        renderer2.removeClass(document.body, 'rtl');
        renderer2.setAttribute(document.body, 'dir', 'ltr');
        if (scrollBars.length) {
          for (let i = 0; i < scrollBars.length; i++) {
            renderer2.setAttribute(scrollBars[i], 'dir', 'ltr');
          }
        }
      }
    }
  }

  public isLanguageRtl(): boolean {
    let isRtl = false;
    const lang = this.getSavedLanguage();

    if (lang) isRtl = this.supportedLanguages[lang].isRtl;

    return isRtl;
  }

  public translateError(err: unknown) {
    if (err instanceof Error) {
      this.logService.error(`Translate error locally and rethrowing it... ${err.message}`, err);

      return this.translateService.get(err.message).pipe(
        switchMap((translation: string) => {
          if (translation) {
            err.message = translation;
          } else {
            this.logService.warn('Translation not found, using original error message');
          }
          return throwError(() => err);
        }),
        catchError((translationError) => {
          this.logService.error('Error occurred during translation', translationError);
          return throwError(() => err);
        })
      );
    }
    return throwError(() => err);
  }
  public translate: (
    key: string | string[],
    interpolateParams?: object | undefined
  ) => Observable<string> = this.translateService.get.bind(this.translateService);
  public translateSync: (key: string | string[], interpolateParams?: object | undefined) => string =
    this.translateService.instant.bind(this.translateService);
  constructor() {
    this.translateService.setDefaultLang('en');
    this.changeLanguage(this.getSavedLanguage() ?? this.translateService.getBrowserLang() ?? 'en');
  }
}
