export const generalVars = {
  typesenseConfig: {
    'nodes': [
      {
        'host': 'jl5y62qruwmda0hop-1.a1.typesense.net',
        'port': 443,
        'protocol': 'https',
      },
    ],
    'apiKey': 'UtVY4gE1f2XDaqLL3JJcobNXTpJg6Zcb',
    'connectionTimeoutSeconds': 2,
  },
  twilio: {
    accountSid: 'ACe9fe2f74ae43d8017ae44508fc7b754e',
    apiKeySid: 'SK7ba83a478eab164a4a0141fe41da5b11',
    apiKeySecret: 'bE2JGrZDNxV5MGWPJ8tsMfKDTsKkUbCR',
    serviceSid: 'IS9290319f5b5645ca8bcd72f816530e95',
    authToken: '8706f2d45f508c4779e0d60444bfbb05',
  },
  cspGuardUrl: 'https://us-central1-terrific-live.cloudfunctions.net/cspGuardV2',
  dockerCompose: false,
};
