import {CommonModule} from '@angular/common';
import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {LanguageService} from 'src/app/language.service';

export type ButtonVariant =
  | 'base'
  | 'primary'
  | 'secondary'
  | 'primary-outline'
  | 'dark'
  | 'dark-blue'
  | 'light'
  | 'share'
  | 'share-poll-up'
  | 'share-sidebar'
  | 'gray'
  | 'gray-dark'
  | 'icon'
  | 'black'
  | 'no-variant';

export type ButtonSize = 'small' | 'large' | 'extra-large' | 'extra-large-circle' | 'no-size';

export type ButtonType = 'button' | 'submit';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  rtl = inject(LanguageService).isLanguageRtl();

  @Input()
  btnClasses: string;

  @Input()
  disabled: boolean | null = false;

  @Input()
  responsive = false;

  @Input()
  rounded = false;

  @Input()
  variant: ButtonVariant = 'primary';

  @Input()
  size: ButtonSize = 'large';

  @Input()
  primaryColor: string;

  @Input()
  customStyle: any;

  @Input()
  type: ButtonType = 'button';

  @Input()
  fullWidth = false;

  @Input()
  responsiveToText = false;

  @Output()
  clickEmitter = new EventEmitter<Event>();

  getVariant(): string {
    const cleanButtonClassNames: ButtonVariant[] = [
      'base',
      'share',
      'share-poll-up',
      'share-sidebar',
    ];

    const variantStyle = `btn-${this.variant}`;
    const spacialBtn = cleanButtonClassNames.includes(this.variant);

    return spacialBtn ? variantStyle : `btn-base ${variantStyle}`;
  }

  getSize(): string {
    return this.size === 'no-size' ? '' : `btn-${this.size}`;
  }

  getSizeAndVariant() {
    return `${this.getVariant()} ${this.getSize()}`;
  }

  clickHandler(event: Event) {
    this.clickEmitter.emit(event);
  }
}
