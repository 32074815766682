import {Injectable, NgZone, inject} from '@angular/core';
import {
  EMPTY,
  Observable,
  Subject,
  asapScheduler,
  asyncScheduler,
  filter,
  finalize,
  observeOn,
  shareReplay,
  subscribeOn,
  take,
  takeUntil,
  timeout,
} from 'rxjs';
import {PaymentResultModel} from 'terrific-shared/dto-models/payments';
import {
  MessagePurpose,
  TerrificMessage,
} from 'terrific-shared/ecommerce-platform-integration/sdk/messages';
import {LogService} from 'src/app/logger/logger.service';

const REASONABLE_TIME_UNTIL_RECEIVED = 1000;
const REASONABLE_TIME_UNTIL_RESPONSE = 1000 * 60; // mimic can take a while

const TransformToResponse = (p: MessagePurpose) => `${p} response` as const;
const TransformToReceived = (p: MessagePurpose) => `${p} received` as const;

@Injectable({
  providedIn: 'root',
})
export class PostMessengerService {
  private zone = inject(NgZone);
  public logService = inject(LogService);

  public sendPostMessageWithPaymentResult(result: PaymentResultModel) {
    this.postMessage({
      messageType: MessagePurpose.GoToCheckout,
      data: result,
    });
  }

  public postMessage<T extends MessagePurpose>(
    data: TerrificMessage<T>,
    windowToSentTo: Window | WindowProxy | null = null
  ) {
    return this.zone.runOutsideAngular(() => {
      this.subjectOfMessagesFromThisFrame.next(data);
      const response = this.listenToMessageFromAnotherFrame(
        windowToSentTo,
        TransformToResponse(data.messageType)
      ).pipe(
        timeout({
          each: REASONABLE_TIME_UNTIL_RESPONSE,
          with: () => EMPTY,
        }),
        shareReplay({refCount: true, bufferSize: 1}),
        subscribeOn(asapScheduler)
      );
      const received = this.listenToMessageFromAnotherFrame(
        windowToSentTo,
        TransformToReceived(data.messageType)
      ).pipe(
        timeout({
          each: REASONABLE_TIME_UNTIL_RECEIVED,
          with: () => EMPTY,
        }),
        shareReplay({refCount: true, bufferSize: 1}),
        takeUntil(response),
        subscribeOn(asapScheduler)
      );
      const {opener, top, parent, self, frames} = window;
      const task = () => {
        // using set to remove duplicates
        const contexts = windowToSentTo
          ? new Set([windowToSentTo])
          : new Set(
              [windowToSentTo, opener as Window | null, top, parent, ...Array.from(frames)].filter(
                (p): p is Exclude<typeof p, null | undefined> => !!p
              )
            );

        // remove self from the list
        contexts.delete(self);

        this.logService.log('[Post Messenger Service] postMessage', data);
        for (const context of contexts) {
          try {
            context.postMessage({...data, type: 'terrific-live-event'}, '*');
            this.listenToMessageFromAnotherFrame(context, MessagePurpose.Loaded)
              .pipe(
                takeUntil(received),
                take(1),
                finalize(() =>
                  this.logService.log(
                    '[Post Messenger Service] postMessage for context finalized',
                    data,
                    'to',
                    context
                  )
                )
              )
              .subscribe({
                next: () => {
                  context.postMessage({...data, type: 'terrific-live-event'}, '*');
                  this.logService.log(
                    '[Post Messenger Service] postMessage after loaded',
                    data,
                    'to',
                    context
                  );
                },
              });
            this.logService.log(
              '[Post Messenger Service] postMessage without verify loaded',
              data,
              'to',
              context
            );
          } catch (error) {
            this.logService.error('[Post Messenger Service] handlePostMessageToHostFrame', error);
          }
        }
      };

      asyncScheduler.schedule(task);

      return {received, response};
    });
  }

  /**
   * Listen to message from another frame
   * If you want to receive only one message, use take(1) on the observable pipe
   */
  public listenToMessageFromAnotherFrame(
    context: Window | WindowProxy | null = null,
    ...messageType:
      | MessagePurpose[]
      | ReturnType<typeof TransformToReceived>[]
      | ReturnType<typeof TransformToResponse>[]
  ) {
    return new Observable<MessageEvent>((subscriber) => {
      this.logService.log('[Post Messenger Service] starting to listen to message', messageType);
      const wrappedCallback = (event: MessageEvent) => {
        const {type, messageType: receivedMessageType} =
          typeof event.data === 'object' ? event.data ?? {} : ({} as any);

        if (type !== 'terrific-live-event') return;
        this.logService.log('[Post Messenger Service] a message was received', event);

        if (context && event.source !== context) {
          this.logService.log(
            '[Post Messenger Service] a message was received from a different context',
            {
              type,
              messageType,
              receivedMessageType,
            }
          );
          return;
        }

        this.logService.log(
          '[Post Messenger Service] a message was received with the terrific namespace',
          {
            type,
            messageType,
            receivedMessageType,
          }
        );
        if (messageType.includes(receivedMessageType)) {
          this.logService.log(
            '[Post Messenger Service] a message was received with the terrific namespace and the correct type',
            event
          );
          subscriber.next(event);
        }
      };
      window.addEventListener('message', wrappedCallback);
      return () => {
        window.removeEventListener('message', wrappedCallback);
        subscriber.complete();
      };
    });
  }
  private subjectOfMessagesFromThisFrame = new Subject<TerrificMessage<MessagePurpose>>();
  public listenToMessagesFromThisFrame<T extends MessagePurpose>(...messageType: T[]) {
    return this.subjectOfMessagesFromThisFrame.pipe(
      observeOn(asyncScheduler),
      filter((message): message is TerrificMessage<T> =>
        messageType.includes(message.messageType as T)
      )
    );
  }
}
