export async function releaseAllVideoDevices() {
  const videoDevices = await navigator.mediaDevices.enumerateDevices();
  const videoTracks = videoDevices
    .filter((device) => device.kind === 'videoinput')
    .map((device) => device.deviceId);

  videoTracks.forEach(async (deviceId) => {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {deviceId: {exact: deviceId}},
    });
    stream.getTracks().forEach((track) => track.stop());
  });
}
